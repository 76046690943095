import { Divider } from "antd";
import '../style/components/advert.css'


const Advert = () => {

    return(
        <div className="ad-div comm-box">
             {/* <div><img src="http://blogimages.jspang.com/flutter_ad2.jpg" width="100%"/></div>
             <div><img src="http://blogimages.jspang.com/flutter_ad2.jpg" width="100%"/></div> */}
        </div>
    )
}

export default Advert;